import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`TuringTrader's Rain or Shine`}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Similar Strategies `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-monthly/"
            }}>{`All-Stars Monthly`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-stocks-on-a-stroll/"
            }}>{`Stocks on a Stroll`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-vix-spritz/"
            }}>{`VIX Spritz`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: balanced growth`}</li>
        <li parentName="ul">{`Type: trend-following strategy`}</li>
        <li parentName="ul">{`Invests in: leveraged ETFs tracking treasuries, stocks, commodities, gold`}</li>
        <li parentName="ul">{`Rebalancing schedule: weekly`}</li>
        <li parentName="ul">{`Taxation: 50% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $6,000`}</li>
      </ul>
      <p>{`TuringTrader’s `}<em parentName="p">{`Rain or Shine`}</em>{` offers balanced growth, similar to the 60/40 benchmark. The strategy is a tactical take on Tony Robbins’ `}<a parentName="p" {...{
          "href": "/portfolios/robbins-all-seasons/"
        }}>{`All-Seasons Portfolio`}</a>{`, reducing risk by adding a trend-following mechanism and boosting returns by leveraging the bond holdings. `}<em parentName="p">{`Rain or Shine`}</em>{` has very low maintenance requirements: while the strategy calls for weekly rebalancing, most weeks, there won’t be anything to do. In summary, `}<em parentName="p">{`Rain or Shine`}</em>{` appeals to conservative investors seeking an alternative to strategic investments.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, Rain or Shine: Our take on the All-Seasons portfolio",
                "title": "TuringTrader.com, Rain or Shine: Our take on the All-Seasons portfolio",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/leveraged-etf-portfolios/"
            }}>{`Leveraged ETF Portfolios`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/robbins-all-seasons/"
            }}>{`All-Seasons Portfolio`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`Rain or Shine`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`start with the `}<em parentName="li">{`All-Seasons`}</em>{` asset allocation, leveraged up to 125%`}
          <ul parentName="li">
            <li parentName="ul">{`37.50% S&P 500`}</li>
            <li parentName="ul">{`18.75% 10-year treasuries`}</li>
            <li parentName="ul">{`25.00% 2x leveraged 30-year treasuries`}</li>
            <li parentName="ul">{`9.375% gold`}</li>
            <li parentName="ul">{`9.375% commodities`}</li>
          </ul>
        </li>
        <li parentName="ul">{`exit the leveraged ETF, based on its ratio between momentum and volatility`}</li>
        <li parentName="ul">{`reduce exposure to other assets by 50%, based on a trend-following filter`}</li>
        <li parentName="ul">{`invest any remaining capital in short-term Treasuries`}</li>
      </ul>
      <p>{`We have always been big fans of the `}<em parentName="p">{`All-Seasons Portfolio`}</em>{`, because of its admirably smooth equity curve and low drawdowns. However, we felt that many investors could not fully appreciate these qualities, given the portfolio's low returns.`}</p>
      <p><em parentName="p">{`Rain or Shine`}</em>{` uses the same baseline asset allocation as the `}<em parentName="p">{`All-Seasons Portfolio`}</em>{` but leveraged up to a total exposure of 125%. This is achieved through holding up to 25% in a 2x leveraged ETF tracking 20+ year U.S. Treasuries. `}<em parentName="p">{`Rain or Shine`}</em>{` uses active management to remedy decay of the leveraged ETF, and to reduce the drag on performance that unfavorable asset classes may have at times. These management methods are applied sparingly, in order to reduce dependency on exact market timing.`}</p>
      <h2>{`Diversification`}</h2>
      <p>{`Just like the `}<em parentName="p">{`All-Seasons Portfolio`}</em>{`, our `}<em parentName="p">{`Rain or Shine`}</em>{`'s is always well-diversified across multiple asset classes. The combination of asset classes should do well in almost all economic environments.`}</p>
      <p>{`Due to the portfolio's dependency on bonds, `}<em parentName="p">{`Rain or Shine`}</em>{` bears some interest-rate risk. Future will tell how the portfolio will fare in an environment of rising interest rates. However, as of early 2020, this is not an imminent issue.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`Compared to the `}<em parentName="p">{`All-Seasons Portfolio`}</em>{`, our `}<em parentName="p">{`Rain or Shine`}</em>{` strategy delivers better performance at a lower risk. This allowed us to apply additional leverage and beat a 60/40 over the full economic cycle, and at a substantially lower risk.`}</p>
      <p><em parentName="p">{`Rain or Shine`}</em>{` delivers outstanding risk-adjusted returns, especially for a strategy with such low maintenance requirements: The `}<em parentName="p">{`Sharpe Ratio`}</em>{` exceeds 1.0, and the `}<em parentName="p">{`Martin Ratio`}</em>{` is around 2.9. Both of these metrics are substantially better than those of the `}<em parentName="p">{`All-Seasons Portfolio`}</em>{` and demonstrate the value of active management.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`When the strategy scales its asset exposure, it triggers taxable events. More than 50% of the total assets are held throughout, leading to long-term taxation of those capital gains. However, interest received from bonds is taxable at the full income tax rate. As `}<em parentName="p">{`Rain or Shine`}</em>{` holds a higher percentage of bonds than typical stock/ bond portfolios, its overall tax burden is slightly higher. `}</p>
      <p><em parentName="p">{`Rain or Shine`}</em>{` makes use of a 2x leveraged index ETF, which is considered a high-risk instrument. Most brokerages require signing additional disclosures before allowing investors to use these instruments in their accounts. We believe our use of this instrument to be responsible and in the spirit of reducing overall portfolio risk.`}</p>
      <p><em parentName="p">{`Rain or Shine`}</em>{` invests in up to six ETFs at a time. Therefore, it requires a minimum investment of about $6,000.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, February 2020`}</a>{`: Initial release, expanding on `}<a parentName="li" {...{
            "href": "/portfolios/robbins-all-seasons/"
          }}>{`Robbins' All-Seasons Portfolio`}</a>{`.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      